.ai_profile_preview_wrapper {
    .ant-typography {
        &.suggestionsListTitle {
            margin: 0 0 24px;
        }
    }
}

.communityImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
}

.CommunityCardCover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
}