.user{   
    padding-top: 48px;
    padding-bottom: 48px;
    width: 800px;
    margin: 0 auto;
    .onboardingSteps{
        height: 550px;
        background-color: violet;
        padding-top: 40px;
        padding-bottom: 16px;
        overflow-y: auto;
    }
}