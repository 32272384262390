.settingsCard{
    display: flex;
    border-top: 1px solid #F0F0F0;
    padding: 24px 0px;
    gap: 32px;
}
.settingsInfo{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 280px;
    .title{
        font-weight: 500;
        color: #000000;
    }
    .description{
      color: rgba(0, 0, 0, 0.65);  
      font-weight: 400;
    }
}