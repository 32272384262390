.PrivacyMainContainer {
    height: calc(100vh - 80px);
    overflow-y: auto;
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    .PrivacyHeader {
        padding: 96px 0;
        max-width: 768px;
        margin: 0 auto;
        text-align: center;
    }

    .PrivacyContainer {
        max-width: 720px;
        margin: 0 auto;
        padding-bottom: 96px;
    }
}