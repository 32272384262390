.nav-container{
    height: 80px;
    width: 100%;
    padding: 0px 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E4E7EC;
}
.selected {
    font-weight: bold;
    color: #0E8DE6;
  }
  