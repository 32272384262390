// Figtree-font-family-----

@font-face {
  font-family: 'Figtree-Light';
  src: url('./font-files/Figtree/Figtree-Light.woff2') format('woff2'),
    url('./font-files/Figtree/Figtree-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree-Regular';
  src: url('./font-files/Figtree/Figtree-Regular.woff2') format('woff2'),
    url('./font-files/Figtree/Figtree-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree-Medium';
  src: url('./font-files/Figtree/Figtree-Medium.woff2') format('woff2'),
    url('./font-files/Figtree/Figtree-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree-SemiBold';
  src: url('./font-files/Figtree/Figtree-SemiBold.woff2') format('woff2'),
    url('./font-files/Figtree/Figtree-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree-Bold';
  src: url('./font-files/Figtree/Figtree-Bold.woff2') format('woff2'),
    url('./font-files/Figtree/Figtree-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}