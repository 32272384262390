.roleSelection {
    width: 100%;
    padding: 100px;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.roleSelectionTitle {
    &.ant-typography {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        color: #000000E0;
        margin-bottom: 0;
        font-family: 'Inter', sans-serif;
    }
}

.roleSelectionHelpertext {
    &.ant-typography {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #000000E0;
        margin-bottom: 0;
        font-family: 'Inter', sans-serif;
    }
}

.card-service {
    background-color: #FFF2E8;
    border: none;
    border-radius: 8px;
    overflow: hidden;
    width: 420px;
    padding: 16px 24px;
    cursor: pointer;

    &.flex-time-services {
        background-color: #FFF4EA;
    }

    .ant-card-body {
        padding: 0;

        .card-headings {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: #000000E0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 24px;
        }

        .card-text-box {
            min-height: 72px;
            overflow: hidden;

            .paragraph-text {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #000000A6;
                margin-bottom: 0;
                font-family: 'Inter', sans-serif;
            }
        }
    }

    .img-icons {
        width: 100%;
        height: 281px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: unset;
        background-color: unset;
        margin-bottom: 0;
    }
}