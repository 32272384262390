.text{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.45);
}
.seeMoreOption{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #3DC1DC;
    margin-left: 4px;
    cursor: pointer;
}