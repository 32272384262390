.Suggestions-list-wrapper{
  background-color: white;
  border-radius: 8px;
  padding: 16px 24px;
  margin-bottom: 16px;
  .ant-typography{
    &.suggestions-list-title{
    margin: 0 0 24px ;
    }
  }
  
}
.ant-flex{
    &.single-list-item{
        width: 100%;
        &:not(:last-child){
            margin-bottom: 12px;
        }
      .ant-flex{
        &.single-item-content{
        .ant-typography{
          margin: 0;
          &:first-child{
            font-size: 14px;
          }
          &:last-child{
            font-size: 12px;
            color: #000000A6;
          }
        }
        }
      }
    }
  }
