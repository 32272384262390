.title{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.88);
}
.description{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    color: rgba(0, 0, 0, 0.65);
}
.headerWrapper{
    margin-bottom: 16px;
}
.addWorkExp{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px dashed #D9D9D9;
    border-radius: 8px;
    padding: 16px;
    align-self: stretch;
    cursor: pointer;
    h5{
font-size: 16px;
font-weight: 500;
line-height: 20px; 
color:rgba(0, 0, 0, 0.88);
    }
    svg{
        width: 16px;
        height: 16px;
        color:rgba(0, 0, 0, 0.45);
    }
}
.workExpContainer{
    max-height: 350px;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 8px;
    padding-top: 8px;
}
.workExpList{
display: flex;
flex-direction: column;
gap: 8px;
margin-top: 8px;
}

.workExp{
padding: 16px;
border-radius: 12px;
border: 1px solid  #F0F0F0;
width: 100%;
}
.workExpDetails{
    margin-left: 28px;
    .workExpDuration{
        font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
color: rgba(0, 0, 0, 0.45);
    }
    .companyName{
        color: rgba(0, 0, 0, 0.88);
    }
}
.suggestedSkills{
    margin-top: 12px;
    display: flex;
padding: 16px;

flex-direction: column;
align-items: flex-start;
gap: 13px;
align-self: stretch;
border-radius:"8px";
background: #FBFBFB;
.skill{
    cursor: pointer;
display: flex;
height: 28px;
padding: 1px 8px;
align-items: center;
gap: 4px;
border-radius: "4px";
border: 1px solid  rgba(0, 0, 0, 0.15);
color:  rgba(0, 0, 0, 0.45);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px
}
}

.availabilityContainer{
    padding: 16px;
    border-radius: 8px;
border: 1px solid  #F0F0F0;
position: relative;
}

.saveSchedule{
    position: absolute;
    top:16px;
    right:16px;
}
.wrap{
    margin-top: 3200px;
}
.customLabel
{
    color: rgba(0, 0, 0, 0.88);
    background-color: aquamarine;
}