@import "../../styles/base/variables";

.headerWrapper {
  padding-left: 120px;
  padding-right: 120px;
  box-sizing: border-box;
  border-bottom: 1px solid $BorderSecondary;
  background-color: $white;
  // position: fixed;
  width: 100%;
  height: 68px;
  z-index: 1000;
  margin-bottom: 0px;

  &.ant-layout-header {
    line-height: 0px;
  }

  .ant-layout-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .navItems {
    height: 68px;
    align-items: center;
  }

  .appLogo {
    max-width: 41px;
    height: 56px;

    img {
      vertical-align: top;
    }
  }

  .notification-bell {
    position: relative;
  }

  .unread-notifications {
    position: absolute;
    background-color: #3DC1DC;
    width: 15px;
    min-width: max-content;
    height: 15px;
    min-height: max-content;
    border-radius: 50%;
    top: 38%;
    right: -4px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.headerSocial {
  position: fixed !important;
}



.searchBar {
  .ant-drawer-extra {
    width: 90%;
  }
}