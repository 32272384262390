.bg-color {
    background-color: unset;
}

.container-layout {
    max-width: 1344px;
    margin: 0 auto;
}

.container-main {
    max-width: 1236px;
    margin: 0 auto;
}

.banner-main {
    padding: 72px 0 48px;
}

.banner-left-box {
    width: 100%;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        
        top: 0;
        background: url('../../assets/images/banner-cut.png');
        background-position: right;
        background-repeat: no-repeat;
        display: block;
        width: 630px;
        height: 559px;

         
    }

    .text-position {
        position: relative;
        z-index: 999;
        top: 10px;
        
       
        .heading-banner {
            font-family: "Poppins", sans-serif;
            font-size: 52px;
            font-style: normal;
            font-weight: 600;
            line-height: 76px;
            text-transform: uppercase;
            background-color: #fff;
            margin: 0;
            color: #000000E0;
            display: flex;
            white-space: nowrap;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            &.width-1 {
                width: 761px;
                border-top-right-radius: 0px;
            }

            &.width-2 {
                width: 500px;
                border-top-right-radius: 0px;
            }

            &.width-3 {
                width: 300px;
            }
        }
    }

    .paragraph-box {
        margin-top: 166px;
        width: 300px;

        .paragraph-text {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #000000A6;
            margin-bottom: 32px;
        }

        .primary-button {
            border-radius: 8px;
            background: #3DC1DC;
            padding: 8px 8px;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            font-family: 'Inter', sans-serif;
            line-height: 24px;
            border-color: #3DC1DC;

            &:hover {
                background-color: rgba($color: #3DC1DC, $alpha: 0.9);
                border-color: rgba($color: #3DC1DC, $alpha: 0.9);
            }
        }

        .secondary-button {
            border-radius: 8px;
            padding: 8px 8px;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            font-family: 'Inter', sans-serif;
            line-height: 24px;
            border-color: #D9D9D9;
            color: #000000E0;

            &:hover {
                color: #3DC1DC;
                border-color: #3DC1DC;
            }
        }
    }
}

.img-banner-small {
    width: 318px;
    height: 440px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-small-img {
    color: #000000A6;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.rounded-btn {
    background-color: #E0EAE8;
    padding: 12px;
    width: 52px;
    height: 52px !important;
    min-width: 52px !important;

    &:hover {
        border-color: #3DC1DC;
        background-color: #3DC1DC;

        img {
            filter: brightness(0) invert(1);
        }
    }

    img {
        width: 28px;
    }
}

@media (min-width: 1290px) {
    .banner-left-box .text-position .heading-banner.width-1 {
        width: 699px;
    }

    .banner-left-box .text-position .heading-banner.width-2 {
        width: 433px;
    }

    .banner-left-box .text-position .heading-banner {
        font-size: 46px;
    }

    .img-banner-small {
        width: 310px;
        height: 428px;
    }
}

@media (max-width: 1290px) {

    // .services-padding{
    //     margin-left: -16px !important;
    //     margin-right: -16px !important;
    // }
    .trust-padding {
        // padding: 0 16px;
    }
}

@media (min-width: 1200px) {
    .banner-left-box .text-position .heading-banner.width-1 {
        width: 638px;
    }

    .banner-left-box .text-position .heading-banner.width-2 {
        width: 373px;
    }

    .banner-left-box .text-position .heading-banner.width-3 {
        width: 240px;
    }

    .banner-left-box .text-position .heading-banner {
        font-size: 40px;
    }

    .img-banner-small {
        width: 100%;
        height: auto;
    }

    .banner-left-box .paragraph-box {
        width: 240px;
        margin-top: 122px;
    }

    // .banner-left-box::after {

    //     width: 560px;
    //     height: 489px;
    // }
}



.services-section {
    padding: 64px 0 64px;
}

.main-section-heading {
    text-align: center;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 44px !important;
    /* 122.222% */
    letter-spacing: -0.72px;
    color: #101828;
    margin-bottom: 20px !important;
}

.services-text-box {
    max-width: 690px;
    margin: 0 auto 64px;

    .paragraph-text {
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #000000A6;
        font-family: "Poppins", sans-serif;
        margin-bottom: 0;
    }
}

//   .services-container{
//     padding: 0;
//     .services-heading{
//         font-size: 40px;
//         font-style: normal;
//         font-weight: 600;
//         line-height: 76px;
//         color: #000000E0;
//         margin-bottom: 24px;
//     }
//     .services-detail-box{
//         .paragraph-text{
//             font-size: 16px;
//             font-style: normal;
//             font-weight: 400;
//             line-height: 24px;
//             color: #000000A6;
//             margin-bottom: 24px;
//             font-family: "Poppins", sans-serif;
//         }
//     }
//   }
//   .img-services{
//     width: 100%;
//     height: 352px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
.ant-btn-primary.ant-btn.primary-button {
    border-radius: 8px;
    background: #3DC1DC;
    padding: 8px 8px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 24px;
    border-color: #3DC1DC;

    &:hover {
        background-color: rgba($color: #3DC1DC, $alpha: 0.9);
        border-color: rgba($color: #3DC1DC, $alpha: 0.9);
    }
}

.services-cards-container {}

.card-services {
    background-color: #FFF4EA;
    border: none;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;

    &.blue-services {
        background-color: #EDFAFA;

        .ant-card-body .rounded-btn {
            background-color: #D8F0F0;
            border: 1px solid #D8F0F0;
        }
    }

    &.purple-services {
        background-color: #EFEDFA;

        .ant-card-body {
            padding: 0;

            .text-body-card {
                padding: 12px 24px 32px;
            }
        }

        .ant-card-body .rounded-btn {
            background-color: #DBD8F0;
            border: 1px solid #DBD8F0;
        }
    }

    &.pink-services {
        background-color: #FDD8D8;

        .ant-card-body {
            padding: 0;

            .text-body-card {
                padding: 12px 24px 32px;
            }
        }

        .ant-card-body .rounded-btn {
            background-color: #F2C2C2;
            border: 1px solid #F2C2C2;
        }
    }

    &.green-services {
        background-color: #ECF8EF;

        .ant-card-body {
            padding: 0;

            .text-body-card {
                padding: 12px 24px 32px;
            }
        }

        .ant-card-body .rounded-btn {
            background-color: #D5EDDB;
            border: 1px solid #D5EDDB;
        }
    }

    .ant-card-body {
        padding: 45px 24px;
        min-height: 202px;

        .card-headings {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: #000000E0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 16px;
        }

        .rounded-btn {
            background-color: #F5E4D5;
            padding: 12px;
            width: 52px;
            height: 52px !important;
            min-width: 52px !important;
            border: 1px solid #F5E4D5;
            box-shadow: none;

            &:hover {
                border-color: #3DC1DC;
                background-color: #3DC1DC;

                img {
                    filter: brightness(0) invert(1);
                }
            }

            img {
                width: 28px;
            }
        }

        .card-text-box {
            min-height: 72px;
            overflow: hidden;

            .paragraph-text {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #000000A6;
                margin-bottom: 0;
                font-family: 'Inter', sans-serif;
            }
        }
    }

    .img-icons {
        width: 100%;
        height: 281px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: unset;
        background-color: unset;
        margin-bottom: 0;
    }
}

.trust-section {
    padding: 96px 0;
}

.faq-section {
    padding: 96px 0;
}

.card-container {
    .card-main {
        border: 1px solid #D9D9D9;
        padding: 24px;
        border-radius: 8px;

        &:hover {
            transition: ease-in-out .5s;
            background-color: #EDFAFA;
            border-color: #EDFAFA;
            cursor: pointer;

            .img-icons {
                background-color: #fff;
            }
        }

        .ant-card-body {
            padding: 0;

            .card-headings {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                color: #000000E0;
                font-family: 'Inter', sans-serif;
                margin-bottom: 16px;
            }

            .card-text-box {
                .paragraph-text {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    color: #000000A6;
                    margin-bottom: 0;
                    font-family: 'Inter', sans-serif;
                }
            }
        }

        .img-icons {
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            background-color: #EDFAFA;
            margin-bottom: 120px;
        }
    }
}

.divider-wrapper{
    
    backgroundColor: "#475467" !important;
     // Divider line color
    height: "0px" !important; // Thickness of the line
    margin: "0px" !important; // Center alignment
    width: "0px" !important;// Width of the divider
}


.accordion-main {
    max-width: 720px;
    margin: 0 auto;

    .ant-collapse-borderless {
        background-color: unset;
    }

    .ant-collapse-item {
        border-bottom: 1px solid #EAECF0;
        padding-bottom: 32px;
    }

    .ant-collapse>.ant-collapse-item:first-child>.ant-collapse-header {
        padding-top: 0;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
        padding: 24px 0 0;

        .ant-collapse-header-text {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            color: #000000E0;
        }
    }

    .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
        padding: 8px 0 0;

        .accordion-body-text {
            margin-bottom: 0;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #000000A6;
        }
    }

    .ant-collapse-header .ant-collapse-arrow svg {
        width: 24px;
        height: 24px;

        path {
            fill: #3DC1DC;
        }
    }

    .ant-collapse-borderless>.ant-collapse-item:last-child {
        padding-bottom: 0;
    }
}