.attach-communities {
    padding-bottom: 16px;
    max-height: 550px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    padding-right: 8px;

}

.attach-communities ::-webkit-scrollbar {
    width: 8px;
  }
  
  .attach-communities ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .attach-communities ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }
  
  .attach-communities ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
 
  