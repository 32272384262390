.createPost{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 32px;

    .createPostTrigger{
width: 100%;

    }
}



.communityPage{
    min-width: 1200px;
    overflow: none;
}

.community-feed{
    margin-top: 32px;
}