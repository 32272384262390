.postModal{
    .info{
        display: flex;
        gap: 12px;
        align-items: center;
        flex-direction: row;
        .title{
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color:  rgba(0, 0, 0, 0.88);
            margin-bottom: 0px;
            }
    }
    .textArea{
        // margin: 12px 0px;
        // display: flex;
    }
    .settings{
        margin: 12px 0px;
    }
    .postSettingContainer{
        display: flex;
        gap: 12px;
        margin: 24px 0px;
        flex-direction: column;
    }
    .postSettings{
        display: flex;
        justify-content: space-between;

    } 
    .customInput{
          //  border: ;
            border-radius: 4px; 
            box-shadow: none; 
            transition: border-color 0.3s, box-shadow 0.3s;
            flex-grow: 1;
            padding-top: 8px;
            padding-bottom: 8px;
    }
    .fileUpload{
        max-height: 300px;
        overflow-y: auto;
    }
    .fileUpload .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
        width: 170px;
        height: 140px;
    }
    .fileUpload  .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container{
        width: 170px;
        height: 140px;
    }
    .fileUpload  .ant-upload-list-picture {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    .popoverButton{
        margin-left: auto;
        position: "absolute"; // Positioning the button inside the TextArea
        top : "10px";          // Adjust as needed for vertical alignment
        right: "10px";        // Adjust as needed for horizontal alignment
        cursor: "pointer"
    }  
    .addCommunitiesBtn{
        height: 22px;
        border-radius: 4px;
    }
}
.videoPreview{
    padding-top: 40px;
}

.custom-popover .ant-popover-inner {
    padding: 0 !important;
    border-radius: 10px 10px 30px 30px;
  
   
}

.borderSetting{
    transform: translate( 2vh, 0vh) !important;
    
}


