
  .title {
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
  
  .cardBody {
    padding: 20px;
    min-height: 120px; 
    border-radius: 10px;
    border: 1px solid #ddd;
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
    white-space: 'pre-wrap' 
  }
  
  .jobDetails {
    min-height: 150px; 
  }
  
  .buttonContainer {
    margin-top: 30px;
  }
  
  .backButton {
    border-radius: 8px;
    border-color: #ddd;
    background-color: #f5f5f5;
    margin-right: 10px;
  }
  
  .submitButton {
    border-radius: 8px;
    background-color: #00b4d8;
    border-color: #00b4d8;
  }
  
  .gutter {
    margin-bottom: 20px; 
  }
  
  .marginTop {
    margin-top: 20px; 
  }
  
  .marginTopSmall {
    margin-top: 10px; 
  }
  .jobDetails{
   
    padding-top: 32px;
    padding-bottom: 24ppx;
  
    
  }
  .formItemColumn {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 5px; 
    }
  }
  .TextColor
  {
      color: rgba(0, 0, 0, 0.65);
      
  }
 .tag{
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  color:blue;
 }
  
.exampleTitles {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
}

.exampleList {
  list-style-type: disc;
  padding-left: 20px;
  color: rgba(0, 0, 0, 0.45);
}

.textAreaContainer {
  margin-top: 100px; 
  background-color: green; 
}
.nextButtonContainer {
  margin-top: 200px; 
  display: flex;
  justify-content: flex-end;
}
.container{
  height:100px;
  overflow-y:auto
}