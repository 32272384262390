.ant-flex {
    &.post-card-header {
        margin-bottom: 16px;

        .ant-typography {
            margin-top: 0;
            font-size: 14px;
        }

        .ant-space {
            .ant-space-item {
                &:first-child {
                    span {
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #D9D9D9;
                        display: block;
                    }
                }

                &:last-child {
                    .ant-typography {
                        color: black;
                        opacity: 0.65;
                    }
                }
            }
        }
    }
}

.post-card-body {
    .ant-typography {
        margin-bottom: 16px;
        display: block;
        color: black;
        opacity: 0.65;
    }

    //#f0f0f0
    .ant-image {
        margin-bottom: 16px;
        border-radius: 8px;
        overflow: hidden;
        background-color: #e3dfdf;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 542px;


        .ant-image-img {
            &.post-image-wrapper {

                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                width: auto;
                height: auto;
            }
        }
    }

     


    .ant-flex {
        &.post-action-details {
            padding-bottom: 16px;
            border-bottom: 1px solid #E6E6E6;

            .ant-typography {
                margin-bottom: 0;
            }

            .ant-btn {
                &.like-action-btn {
                    background-color: #FFEFEF;
                    border: none;
                    cursor: default
                }
            }
        }
    }
}

.ant-flex {
    &.post-card-actions {
        padding: 12px 0 8px;
        margin-bottom: 16px;

        .ant-btn {
            height: 36px;
        }

    }
}

.post-comments-wrapper {
    .ant-flex {
        &.write-comment-wrapper {
            margin-bottom: 16px;

            .ant-avatar {
                width: 48px;
                min-width: 48px;
            }
        }
    }

    .ant-btn {
        &.show-comments-list-btn {
            padding: 0;
        }
    }
}

.inner-comments-wrapper {
    padding: 0;
    margin: 16px 0 0;

    .ant-flex {
        &.single-comment {
            margin-bottom: 24px;

            .ant-avatar {
                min-width: 32px;
                min-height: 32px;
            }

            .ant-flex {
                &.comment-message {
                    .ant-typography {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.video{
    
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: auto;
    height: auto;
    
}
.video-cont{
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #e3dfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 542px;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.video-container:hover .video-overlay {
    opacity: 1;
}

.play-icon {
    font-size: 48px;
    color: white;
}

.community-card-header {
    margin-bottom: 24px;

    .image-wrapper {
        position: relative;
        cursor: pointer;

        .community-image {
            border: 2px solid #FFFFFF;
            box-shadow: 0px 2px 4px 0px #00000005;
        }

        .user-image {
            box-shadow: 0px 2px 4px 0px #00000005;
            bottom: -5px;
            position: absolute;
            left: 25px;

            .user {
                border: 2px solid #FFFFFF;

            }

        }
    }
}

.arrow-edit {
    .ant-carousel .slick-prev {

        background-color: rgb(0 0 0 / 60%) !important;
        border-radius: 45% !important;

        color: white;


    }

    .ant-carousel .slick-next {
        background-color: rgb(0 0 0 / 60%) !important;
        border-radius: 45% !important;

        color: white;
    }

//     .ant-carousel .slick-prev,
// .ant-carousel .slick-prev:hover,
// .ant-carousel .slick-prev:focus {
//   font-size: inherit;
//   left: 10px;
//   z-index: 2;
//   color: blueviolet;
// }

// .ant-carousel .slick-next,
// .ant-carousel .slick-next:hover,
// .ant-carousel .slick-next:focus {
//   font-size: inherit;
//   right: 10px;
//   z-index: 2;
//   color: blueviolet;
// }

// /* Hide only the default arrows, not the custom ones */
// .ant-carousel .slick-prev .ant-carousel .slick-next{
   
//     display: none !important;
//   }
  
//   /* Alternatively, if visibility doesn't work, you can use display */
//   .ant-carousel .anticon .anticon-right .slick-arrow .slick-next {
//     visibility: visible !important;  /* Make sure custom arrows are visible */
//   }
  
/* Target and hide default previous and next arrows */
.ant-carousel .slick-prev:not([class*="anticon"]),
.ant-carousel .slick-next:not([class*="anticon"]) {
  display: none !important;
}


}