.modalWrappermain {
    .ant-modal-content {
        padding: 0 !important
    }

    .ant-modal-footer {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        padding-right: 24px !important;
    }

    .ant-modal-header {
        padding-top: 16px !important;
        padding-left: 24px !important;
    }
}

.linkTag {
    color: rgba(47, 84, 235, 1) !important;
    text-decoration: underline !important
}

.inputText {
    color: '#000000E0'
}

.RadioButton {
    .ant-radio-wrapper {
        flex-direction: row-reverse;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        padding: 12px 24px 12px 16px;
        width: 50%;
        margin-inline-end: 0;
        gap: 4px;

        span {
            &:last-child {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }

        .ant-radio {
            .ant-radio-inner {
                width: 16px !important;
            }
        }

        &.ant-radio-wrapper-checked {
            border: 2px solid #3DC2DC;
        }
    }
}