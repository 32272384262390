.community-details{
font-size: 12px;
font-weight: 400;
line-height: 18px;
color: rgba(0, 0, 0, 0.65);
}
.capitalize{
    text-transform: capitalize;
}
.community-list-item-avatar{
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 2px 4px rgba(0, 0, 0, 0.08);
              min-width: 40px;
}