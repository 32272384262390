@import '../../styles/base/variables';
@import '../../styles/base/typography';


.FooterContainer {
    .FooterWrapper {
        padding: 0 80px;
        gap: 32px;
        margin-bottom: 64px;
        align-items: start;
        justify-content: space-around;

        .DarkLogo {
            margin-left: -20px;
            margin-top: -10px;
            margin-bottom: -12px;
        }

        li {
            @extend .text-lg-500;
            color: $Gray200;
            cursor: pointer;
        }
    }
}