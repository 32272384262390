@import '../../styles/base/variables';
@import '../../styles/base/typography';

.suggestions-list-wrapper{
  background-color: white;
  border-radius: 8px;
  margin-bottom: 16px;
    padding: 16px 24px;
    .ant-typography{
      &.suggestions-list-title{
      margin: 0 0 24px ;
      }
    }
    
  }
  .ant-flex{
      &.single-list-item{
          width: 100%;
          &:not(:last-child){
              margin-bottom: 12px;
          }
        .ant-flex{
          &.single-item-content{
          .ant-typography{
            margin: 0;
            &:first-child{
              font-size: 14px;
            }
            &:last-child{
              font-size: 12px;
              color: #000000A6;
            }
          }
          }
        }
      }
    }

    .create-community{
        background-color: #E6F4FF;
        width: 100%;
        margin-top: 16px;
        color: #08979C;
        border: none;
        min-width: max-content;

        &:hover{
        background-color: #E6F4FF !important;
        }
    }



.ProfileContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .profileScreenScroll {
        height: calc(100vh - 372px);
        overflow-y: auto;
        -ms-overflow-style: none !important;
        /* IE and Edge */
        scrollbar-width: none !important;
        /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .lockCard {
        padding: 32px 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
    }
}





// User profile View--------------------

.profile-view-scroll {
    height: calc(100vh - 128px);
    overflow-y: auto;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    &::-webkit-scrollbar {
        display: none;
    }
}

.user-stats {
    border-radius: 8px;
    background-color: $volcano1;
}

.skill-pill {
    padding: 4px 8px;
    border-radius: 24px;
    border: 1px solid $geekblue3;
    background-color: $geekblue1;
    @extend .text-xs-500;
    color: $geekblue8;
}


.edit-user-image {
    position: relative;
    margin-bottom: 16px;

    .edit-icon {
        position: absolute;
        bottom: 0;
        left: 53px;
        cursor: pointer;
    }
}

.suggested-tags{
  display: flex;
padding: 8px;
border-radius: 8px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-radius:"8px";
background: #FBFBFB;
.skill{
  cursor: pointer;
display: flex;
height: 24px;
padding: 1px 8px;
align-items: center;
gap: 4px;
border-radius: "4px";
border: 1px solid  rgba(0, 0, 0, 0.15);
color:  rgba(0, 0, 0, 0.45);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px
}
}

.create-community-modal{
  max-height: 650px;
  overflow-y: auto;
}