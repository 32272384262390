@import "./fonts";
@import "./variables";
@import "./typography";

body {
  font-family: $FigtreeRegular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // color: $textColor;
  // background-color: $ashColor !important;
}

.mainContainer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;

  @media screen and (min-width: 576px) {
    // max-width: 540px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (min-width: 768px) {
    max-width: 720px;
    padding-left: 12px;
    padding-right: 12px;
  }

  @media screen and (min-width: 992px) {
    max-width: 960px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1184px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 1224px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1384px;
  }
}

.HeaderWrapper {
  padding: 8px 120px;
  box-shadow: 0px 1px 0px 0px #E4E7EC;

  .HeaderBounds {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.AppLogoWrap {
  max-width: 41px;
  height: 64px;

  img {
    vertical-align: unset;
  }
}

.AppDarkLogo {
  height: 84px;
  width: 84px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}


.SiderWrapper {
  padding: 32px 20px;
  border-right: 1px solid $BorderSecondary;
  background-color: $white;
  height: 100vh;
  position: fixed;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

.ContentContainer {
  padding: 60px 120px;
  height: calc(120vh - 80px);


  .ContentWrap {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

}

.profileWrapper {
  .leftPanel {
    .ant-card-body {
      padding: 24px 24px 216px 16px;
    }
  }

  .completeProfileCard {
    .ant-card-body {
      background-color: $volcano1;
      background-image: url('../../assets/common/profileWaves.png');
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}

.SiderContentContainer {
  margin-top: 68px;
  padding-top: 48px;
  padding-right: 120px;
  padding-bottom: 48px;
padding-left: 120px;
min-height: calc(100vh - 68px); 
  background-color: $ashColor !important;


  .socialMediaScreen {
    margin: 0 auto;

    .socialMediaScroll {
      height: calc(100vh - 96px);
      overflow-y: auto;
      -ms-overflow-style: none !important;
      /* IE and Edge */
      scrollbar-width: none !important;
      /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}


.Input {
  @extend .text-md-400;
  padding: 8px 11px;
  // padding: 9px 12px;
  // border-radius: 4px;
  // border: 1px solid rgba(255, 255, 255, 0);
  // background-color: $ashColor;
  // color: $subHeadingColor;
  // min-height: 40px;
  // display: block;
  // width: 100%;


  &_label {
    @extend .text-sm-400;
    color: $textColor;
    margin-bottom: 4px;
  }

  // &::placeholder {
  //   @extend .text-md-400;
  //   color: $grey4Color;
  // }

  &:focus {
    outline: none;
  }
}

// CustomButtons-------

// .loginBtn {
//   padding-top: 7px;
//   padding-bottom: 7px;
//   height: unset;
//   background-color: $primaryColor;
// }

.d_btn {
  border-radius: 4px;
  border: 1px solid transparent;
  height: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  &_primary {
    @extend .text-sm-600;
    padding: 5px 12px;
    background-color: $primaryColor;
    color: $white;
  }

  &_white {
    @extend .text-sm-600;
    padding: 5px 12px;
    background-color: $white;
    color: black;
    border-color: $strokeColor;
  }

  &_white_30 {
    @extend .text-sm-600;
    padding: 5px 12px;
    background-color: $white;
    color: black;
    border-color: $strokeColor;
    height: 30px;
  }

  &_primary_30 {
    @extend .text-sm-600;
    padding: 5px 12px;
    background-color: $primaryColor;
    color: $white;
    height: 30px;
  }

  &_white_w40_h120 {
    @extend .text-sm-600;
    padding: 5px 12px;
    background-color: $white;
    color: black;
    border-color: $strokeColor;
    height: 30px;
    width: 120px;
  }

  &_primaryTrans400 {
    @extend .text-sm-400;
    padding: 5px 12px;
    background-color: $white;
    color: $primaryColor;
    border: 1px solid $primaryColor;
  }

  &_primaryMd {
    @extend .text-md-600;
    padding: 8px 16px;
    background-color: $primaryColor;
    color: $white;
    width: 124px;
  }

  &_primarySm {
    @extend .text-sm-400;
    padding: 8px 16px;
    background-color: $primaryColor;
    color: $white;
    width: 124px;
  }

  &_whiteMd {
    @extend .text-md-600;
    padding: 8px 16px;
    border-color: $strokeColor;
    background-color: $white;
    color: $subHeadingColor;
    width: 124px;
  }

  &_smallWhite {
    @extend .text-sm-600;
    padding: 5px 12px;
    border-color: $strokeColor;
    background-color: $white;
    color: $subHeadingColor;
  }

  &_whiteSm {
    @extend .text-sm-600;
    padding: 8px 16px;
    border-color: $strokeColor;
    background-color: $white;
    color: $subHeadingColor;
    width: 124px;
  }

  &__whiteMd {
    @extend .text-md-600;
    padding: 8px 16px;
    border-color: $strokeColor;
    background-color: $white;
    color: $subHeadingColor;
  }

  &_primaryXsm {
    @extend .text-xsm-400;
    padding: 4px 8px;
    background-color: $primaryColor;
    color: $white;
  }

  &_whiteXsm {
    @extend .text-xsm-400;
    padding: 4px 8px;
    border-color: $strokeColor;
    background-color: $white;
    color: $subHeadingColor;
  }

  &_dualIconXsm {
    @extend .text-xsm-400;
    padding: 5px 8px;
    border-color: $strokeColor;
    background-color: $white;
    color: $grey48Color;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $greyBg;
    color: $disabledStroke;
  }
}

// CustomFullWidthButtons--------

.btn_w100 {
  @extend .text-md-600;
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid transparent;

  &_primary {
    background-color: $primaryColor;
    color: $white;
  }

  &_white {
    background-color: $white;
    color: $subHeadingColor;
    border-color: $strokeColor;
  }
}

// LoginScreenDefaultStyles--------------------

.Select {

  &_label {
    margin-bottom: 4px;
    @extend .text-sm-500;
    color: $textColor;
  }
}

.label500 {
  margin-bottom: 4px;
  @extend .text-sm-500;
  color: $textColor;
}

.feedbackModal {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.CustomModalWrapper {
  .ant-modal-content {
    padding: 0;

    .ant-modal-header {
      padding: 16px 24px 8px 24px;
      margin-bottom: 0;
    }

    .ant-modal-body {
      padding: 12px 24px;
    }

    .ant-modal-footer {
      padding: 12px 24px;
      margin-top: unset;
    }
  }
}


.TopCheckboxWrap {
  .ant-checkbox {
    align-self: start;
    margin-top: 3px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.dot {
  border-radius: 50%;
  background-color: $BorderColor;

  &_small {
    width: 8px;
    height: 8px;
  }
}

.Input_errorMessage {
  color: red;
}

.SearchWrapper {
  .ant-input-wrapper {
    .ant-input-affix-wrapper {
      padding: 5px 8px;
      border-start-start-radius: 6px;
      border-end-start-radius: 6px;
      border-right-width: 0px;
    }

    .ant-input-group-addon {
      .ant-input-search-button {
        border-start-end-radius: 6px;
        border-end-end-radius: 6px;
        border-left-width: 0px;
        height: 34px;
      }
    }
  }
}


// stream chat theme
.str-chat {
  --str-chat__primary-color: #3DC1DC !important;
}


.ai_profile_preview_drawer {
  .ant-card-cover {

    border-radius: 12px 12px 0 0;
  }

  .ant-drawer-footer {
    padding: 16px 24px !important;
  }

  .ant-card-body {
    padding: 12px;
  }

  .outer-wrapper {
    .ant-card-body {
      padding: 0px;

      .inner-wrapper {
        padding: 9px 12px 12px 12px;

        .ant-avatar {
          border: 2px solid var(--White, #FFF);
        }

        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}