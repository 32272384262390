@import '../../styles/base/variables';
@import '../../styles/base/typography';

.ProfileContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .profileScreenScroll {
        height: calc(100vh - 372px);
        overflow-y: auto;
        -ms-overflow-style: none !important;
        /* IE and Edge */
        scrollbar-width: none !important;
        /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .lockCard {
        padding: 32px 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
    }
}





// User profile View--------------------

// .profileViewScroll {
//     height: calc(100vh - 128px);
//     overflow-y: auto;
//     -ms-overflow-style: none !important;
//     /* IE and Edge */
//     scrollbar-width: none !important;
//     /* Firefox */

//     &::-webkit-scrollbar {
//         display: none;
//     }
// }

.UserStats {
    border-radius: 8px;
    background-color: $volcano1;
}

.SkillsPill {
    padding: 4px 8px;
    border-radius: 24px;
    border: 1px solid $geekblue3;
    background-color: $geekblue1;
    @extend .text-xs-500;
    color: $geekblue8;
}

.workDetailTruncate {
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: normal;
    // word-wrap: break-word;
}

.EditUserImg {
    position: relative;

    .editIcon {
        position: absolute;
        bottom: 0;
        left: 53px;
        cursor: pointer;
    }
}

.editProfileImage {
    position: relative;
    margin-bottom: 16px;

    .editProfileIcon {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 70px;
    }
}