@import "./fonts";
@import "./variables";


// updated AGP----------------starts

.primaryColor {
  color: $primaryColor;
}

.TertiaryColor {
  color: $TertiaryColor;
}

.text-xs-500 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.text-sm-400 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.text-sm-500 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.text-lg-500 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.text-lg-400 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-18-400 {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.text-20-400 {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}


.text-22-400 {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
 
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mt-12 {
  margin-top: 12px;
}

// fonts----


.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

// updated AGP----------------end




























// updated----------------ends



.secondary-color {
  color: $secondaryColor;
}

.white-color {
  color: $white;
}

// .danger-color {
//   color: $dangerColor;
// }

.heading-color {
  color: $headingColor;
}

.subHeading-color {
  color: $subHeadingColor;
}

.grey36Color {
  color: $grey36Color;
}

.grey48Color {
  color: $grey48Color;
}

.BaseSecondColor {
  color: $BaseSecond;
}

.AvatarColor {
  color: $avatarclr;
}

.BaseMainColor {
  color: $BaseMain;
}

.BlueFieldBg {
  background-color: $blueField;
}

.greyBg {
  background-color: $greyBg;
}

.grey46Color {
  color: $grey46Color;
}

.grey10Color {
  color: $grey10Color;
}

.textColor3 {
  color: $textColor3;
}

.greyStrokeColor {
  color: $greyStroke;
}

.calanderHeadercolor {
  color: $calanderHeadercolor;
}

.textColor1 {
  color: $textColor1;
}

.avatarBg {
  background-color: $avatarBg;
}

.text-xl-700 {
  font-family: $FigtreeSemiBold;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.text-lg-600 {
  font-family: $FigtreeSemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.text-md-600 {
  font-family: $FigtreeSemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.text-md-500 {
  font-family: $FigtreeMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.text-md-400 {
  font-family: $FigtreeRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.text-15-400 {
  font-family: $FigtreeRegular;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-sm-700 {
  font-family: $FigtreeBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.text-sm-600 {
  font-family: $FigtreeSemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-sm-500 {
  font-family: $FigtreeMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.text-sm-400 {
  font-family: $FigtreeRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-xsm-700 {
  font-family: $FigtreeBold;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.text-xsm-600 {
  font-family: $FigtreeSemiBold;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.text-xsm-500 {
  font-family: $FigtreeMedium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.gray-light-black {
  color: #333;
}

.text-xsm-400 {
  font-family: $FigtreeRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.font-family-imp {
  font-family: $FigtreeRegular !important;
}

.font-family-imp p,
.font-family-imp label {
  font-family: $FigtreeRegular !important;
}

.text-xxsm-600 {
  font-family: $FigtreeSemiBold;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.text-xxsm-400 {
  font-family: $FigtreeRegular;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

// updated----------------ends

// .text-16-300 {
//   // font-family: $RobotoRegular;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 300;
//   line-height: normal;
//   // letter-spacing: 0.256px;

//   @media screen and (max-width: 991px) {
//     font-size: 14px;
//   }

//   @media screen and (max-width: 767px) {
//     font-size: 12px;
//   }
// }

// spacing----in use

.me-6 {
  margin-right: 6px;
}

.ms-6 {
  margin-left: 6px;
}

.mt-6 {
  margin-top: 6px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.ms-12 {
  margin-left: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.me-20 {
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-22 {
  margin-top: 22px;
}

.mt-25 {
  margin-top: 25px;
}

.p-6 {
  // padding: 6px;
}

.me-12 {
  margin-right: 12px;
}

.px-16 {
  padding: 0 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.column-gap-10 {
  column-gap: 10px;
}

.column-gap-12 {
  column-gap: 12px;
}

// spacing----not in use

.mb-32 {
  margin-bottom: 32px;
}

.mt-32 {
  margin-top: 32px;
}


.ms-32 {
  margin-left: 32px;
}

.mb-56 {
  margin-bottom: 56px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-28 {
  margin-bottom: 28px;
}

.w-49 {
  width: 49% !important;
}

.w-40 {
  width: 40% !important;
}

// background-colors-----

// border-radius-----
.br-1 {
  border-radius: 8px;
}

.br-2 {
  border-radius: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.me-10 {
  margin-right: 10px;
}

.ms-10 {
  margin-left: 10px;
}

.gap-12 {
  gap: 12px;
}


// .g-10 {
//   gap: 10px;
// }

// .w-37 {
//   width: 37% !important;
// }