@import '../../styles/base/variables';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
    width: 100vw;
    /* Adjust based on your layout */
    // background-color: #f0f2f5;
    /* Example background */
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 450px;
    padding: 32px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.loginTitle {
    color: var(--Text-colorTextHeading, rgba(0, 0, 0, 0.88));
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    margin-bottom: 8px;
}

.loginIconsWrap {
    padding: 10px;
    border-radius: 38px;
    border: 1px solid #D0D5DD;
    background-color: #fff;
    display: flex;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    cursor: pointer;
}

.helperText {
    color: var(--Text-colorTextSecondary, rgba(0, 0, 0, 0.65));
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    margin-bottom: 32px;
}

.socialIcons {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
}

.orDivider {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

    p {
        margin: 0 12px;
    }

    .line {
        flex: 1;
        border: 0;
        border-top: 1px solid #d9d9d9;
    }
}

.options {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin: 12px 0 134px 0;
    text-align: center;

    .forgot {
        cursor: pointer;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}


// .loginButton {
//     width: 100%;
//     border-radius: var(--BorderRadius-borderRadiusLG, 8px);
//     background: #3dc1dc;
//     color: white;
//     font-weight: bold;
//     margin-bottom: 8px;
// }

.termsText {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
    color: var(--Text-colorTextSecondary, rgba(0, 0, 0, 0.65));

    span {
        color: var(--Text-colorText, rgba(0, 0, 0, 0.88));
        font-weight: 500;
        cursor: pointer;
    }
}

.AuthContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
    width: 100vw;

    .AuthWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        width: 450px;
        padding: 32px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

        .OrDivider {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 8px;

            hr {
                flex: 1;
                border: 0;
                border-top: 1px solid #D9D9D9;
            }
        }

        .forgotContent {
            // padding: 10px 0px;
            display: flex;
            flex-direction: column;


            .otpCodeWrap {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 20px;

                .otpCodeItem {
                    padding: 12px 8px;
                    border-radius: 8px;
                    border: 1px solid rgba(61, 193, 220, 0.14);
                    background: #fff;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    width: 80px;
                    height: 96px;
                    text-align: center;
                    font-family: Inter;
                    font-size: 60px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 72px;
                    letter-spacing: -1.2px;
                    color: $primaryColor;

                    &:focus {
                        border: 4px solid rgba(61, 193, 220, 0.18);
                    }
                }
            }
        }

    }
}