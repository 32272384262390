@import '../../styles/base/variables';
@import '../../styles/base/typography';

.create_gig_btn {
    padding: 99px 141px;
    border-color: #D9D9D9;
    color: #000000E0;

    span {
        display: inline-flex;
    }
}

.SkillsPill {
    padding: 4px 8px;
    border-radius: 24px;
    border: 1px solid $geekblue3;
    background-color: $geekblue1;
    @extend .text-xs-500;
    color: $geekblue8;
}