.my-scrollable-div {
  overflow: scroll; 
  scrollbar-width: auto; 
  scrollbar-color: auto; 
}

/* For WebKit browsers (Chrome, Safari, Edge) */
.my-scrollable-div::-webkit-scrollbar {
  width: 4px; 
  height: 4px; 
}

.my-scrollable-div::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.my-scrollable-div::-webkit-scrollbar-thumb {
  background: #c1c1c1; 
  border-radius: 10px; 
}


.my-scrollable-div {
  -ms-overflow-style: scrollbar; 
}
