/* styles.css */
.custom-text-style {
    
    color: '#00000073';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  
.text-lg-semibold {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.text-style {
  font-family: 'Inter' !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000000E0 !important;
}  

.text-style-lines{
  font-family: 'Inter'!important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #000000E0 !important;
  
}
.description{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  color: rgba(0, 0, 0, 0.65);
  margin-top:'8px'
}