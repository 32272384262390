.chat-management-container {
    height: 600px;
    display: flex;
    border-radius: 10px; /* Outer border radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    overflow: hidden; /* Ensures content respects border radius */
}

.chat-management-container .str-chat__channel-list {
    width: 30%;
}

.chat-management-container .str-chat__channel {
    width: 100%;
    height: auto;
}

.chat-management-container .str-chat__thread {
    width: 100%;
}

.custom-channel-preview {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s ease;
}

.custom-channel-preview:hover {
  background-color: #f8f9fa;
}

.channel-preview-content {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

.channel-preview-title {
  font-size: 16px;
  font-weight: 600;
  color: #212529;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.channel-preview-last-message {
  font-size: 14px;
  color: #6c757d;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 2px;
}


.custom-channel-preview.selected {
  background-color: #e9ecef; /* Highlight color for selected channel */
  border-left: 4px solid #007bff; /* Add a border to indicate selection */
}


.custom-channel-header {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.channel-header-content {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.channel-header-title {
  font-size: 18px;
  font-weight: bold;
  color: #212529;
}

.channel-header-subtitle {
  font-size: 14px;
  color: #6c757d;
}


