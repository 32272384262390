.tabContainer{
    // padding-top: 16px;
    .actionSection{
        display: flex;
        gap: 8px;
        justify-content: space-between;
        width: 380px;
    }
    .actionSectionVertical{
        flex-direction: column;
        justify-content: unset;
        gap: 16px;
    }
    .deleteBtnWrapper{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

