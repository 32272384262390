.goToBtn{
    display: flex;
    align-items: center;
    width: 370px;
    gap: 8px;
    border: 1px solid  #F0F0F0;
    border-radius: 8px;
    padding: 12px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.88);
    cursor: pointer;
    .arrowIcon{
        margin-left: auto;
        color: #141B34;
    }
   
    .title{
        line-height: 22px;
        font-weight: 400;
        font-size: 14px;
    }
}
.danger {
    color: #CF1322;
}