
.header {
    height: 220px;
    background-color:#EEEEEE;
    border-radius: 4px;
    position: relative;
    background-size: cover;            /* Ensures the image covers the entire div */
    background-position: center;       /* Centers the image */
    background-repeat: no-repeat;      /* Prevents the image from repeating */

    &:hover {
        .upload-cover-btn-hidden {
          display: block !important;
        }
      }


    .community-avatar {
        position: absolute;
        bottom: -70px;
        left: 20px;
.avatar {
    width: 100px;
    height: 100px;
box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
border: 4px solid rgba(255, 255, 255, 1)

}
    }

  
}

.header-actions {
    display: flex;
    margin-left: 140px;
    padding-top: 16px;
    justify-content: space-between;
    align-items: center;
}

.upload-cover{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 45%;
   
}
.upload-cover-btn-hidden{
    display: none;
}
.community-description{
    .ant-typography {
        margin-bottom: 16px;
        display: block;
        color: black;
        opacity: 0.65;
       
    }

    width: 1120px;
    padding-top: 24px;
    
    .text{
        
font-size: 14px;
font-weight: 400;
line-height: 20px;
color: rgba(0, 0, 0, 0.88);
text-align: justify; 




    }
}