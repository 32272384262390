// fonts variables-----

$FigtreeLight: "Figtree-Light";
$FigtreeRegular: "Figtree-Regular";
$FigtreeMedium: "Figtree-Medium";
$FigtreeSemiBold: "Figtree-SemiBold";
$FigtreeBold: "Figtree-Bold";

// colors AGP start----

$primaryColor: #3DC1DC;
$textColor : rgba(0, 0, 0, 0.88);
$BorderSecondary : #F0F0F0;
$BorderColor : #D9D9D9;
$Gray200 : #E4E7EC;
$Gray400 : #98A2B3;
$volcano1 : #FFF2E8;
$volcano2 : #FFD8BF;
$TertiaryColor : rgba(0, 0, 0, 0.45);
$geekblue1 : #F0F5FF;
$geekblue3 : #ADC6FF;
$geekblue8 : #10239E;







// colors AGP end----















$secondaryColor: #564f97;
$lightPrime: #ece7fd;
$extralightPrime: #f8f7fc;

$white: #ffffff;
$headingColor: #050505; // Colors-Grey-2
$subHeadingColor: #242424; // Neutral-Foreground-1-Rest
$secondaryColor: #564f97;
$lightPrime: #ece7fd;
$extralightPrime: #f8f7fc;

$textColor1: #292929; // Neutral-Background-Inverted-Rest
$textColor2: #424242;
$textColor3: #000000;

$ashColor: #f5f5f5; // Neutral-Background-1-Hover

$grey36Color: #5c5c5c; // Colors-Grey-36
$grey4Color: #707070; // Neutral-Foreground-4-Rest
$grey84Color: #d6d6d6; // Neutral-Foreground-84-Rest
$grey46Color: #757575; // Neutral-Foreground-84-Rest
$grey92Color: #ebebeb; // Neutral-Foreground-84-Rest
$grey10Color: #1a1a1a; // Neutral-Foreground-84-Rest
$grey86Color: #dbdbdb; // Neutral-Foreground-84-Rest
$grey40: #666;
$grey48Color: #7a7a7a; // Colors-Grey-48  ----> pagination color
$BaseThird: #c1c3c7; // Color-Tokens-Icons-Modes-Base-Third ----> pagination item color
$avatarBg: #e6e6e6;
$avatarclr: #616161;

$logoBrColor: #ededed;
$strokeColor: #d1d1d1;
$stroke2Color: #e0e0e0;
$strokeBase: #eaebf0;
$BaseMain: #252525;
$BaseSecond: #68727d;
$errorMsg: #b10e1c;
$errorBorder: #c50f1f;
$lightPurple: #f0ecfc;
$blueField: #b1e6fc;
$strokeHover: #c7c7c7;
$disabledStroke: #bdbdbd;
$calendarSelect: #f6f6f8;
$redColor: #d13438;
$conflictColor: #eeacb2;
$DarkRed: #750b1c;
$LightRed: #e9c9c9;
$DarkGreen: #69797e;
$LightGreen: #cdd2d5;

$ashColor: #f5f5f5; // Neutral-Background-1-Hover

$grey36Color: #5c5c5c; // Colors-Grey-36
$grey4Color: #707070; // Neutral-Foreground-4-Rest
$grey84Color: #d6d6d6; // Neutral-Foreground-84-Rest
$grey46Color: #757575; // Neutral-Foreground-84-Rest
$grey92Color: #ebebeb; // Neutral-Foreground-84-Rest
$grey10Color: #1a1a1a; // Neutral-Foreground-84-Rest
$grey86Color: #dbdbdb; // Neutral-Foreground-84-Rest
$grey40: #666;
$grey48Color: #7a7a7a; // Colors-Grey-48  ----> pagination color
$BaseThird: #c1c3c7; // Color-Tokens-Icons-Modes-Base-Third ----> pagination item color
$avatarBg: #e6e6e6;
$avatarclr: #616161;

$logoBrColor: #ededed;
$strokeColor: #d1d1d1;
$stroke2Color: #e0e0e0;
$strokeBase: #eaebf0;
$BaseMain: #252525;
$BaseSecond: #68727d;
$errorMsg: #b10e1c;
$errorBorder: #c50f1f;
$lightPurple: #f0ecfc;
$blueField: #b1e6fc;
$strokeHover: #c7c7c7;
$disabledStroke: #bdbdbd;
$calendarSelect: #f6f6f8;
$redColor: #d13438;
$conflictColor: #eeacb2;
$DarkRed: #750b1c;
$LightRed: #e9c9c9;
$DarkGreen: #69797e;
$LightGreen: #cdd2d5;

// pills------------

$successStroke: #107c10;
$successBg: #f1faf1;
$successStroke: #107c10;
$successBg: #f1faf1;

$warningStroke: #da3b01;
$warningBg: #FAE6E7;
$highPriority: #fdf3f4;

$purpleBg: #d6d5e6;

$greyStroke: #3d3d3d;
$greyBg: #f0f0f0;

$blueStroke: #0f6cbd;
$blueBg: #ebf3fc;

$orangeStroke: #ff8c00;
$orangeBg: #fff9f5;
$onTrack: #5aa55a;
$offTrack: #edb126;
$atRisk: #d1424f;

$calanderHeadercolor: #1f1f1f;


$purpleBg: #d6d5e6;

$blueStroke: #0f6cbd;
$blueBg: #ebf3fc;
// updated----------------ends

$dangerColor: #f23985;

$darkColor: #141414;