.visibilityCard{
    display: flex;
    border-bottom: 1px solid #F0F0F0;
    padding:16px 0px;
    justify-content: space-between;
}
.settingsInfo{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 280px;
    .title{
        font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; 
        color: rgba(0, 0, 0, 0.88);
    }
    .description{
      color: rgba(0, 0, 0, 0.65);  
      font-weight: 400;
    }
}