.userExperience{
    padding: 0px 250px;
    min-width: 1300px;
    max-width: 1440px;
    overflow-x: auto;
    max-height: 900px;
    overflow-y: auto;
    .onboardingSteps{
        height: 550px;
        padding-top: 40px;
        padding-bottom: 16px;
        overflow-y: auto;
    }
}