body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif !important;
}

html,
body {
  width: 100% !important;
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(./fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(./fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(./fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(./fonts/Inter-Regular.ttf) format('truetype');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapperClassName2 {
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(27, 27, 27, 0.25);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

h1,
h2,
h3,
h4,
h5,
span,
h6,
p,
div,
a
 {
  font-family: Inter !important
}

button {
  background-color: transparent;
  border: none;
}

.cursorPointer {
  cursor: pointer;
}

.dropdown-item:focus {
  outline: none !important;
}

.dropdown-button:active {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.65) !important;
}


a {
  color: inherit !important;
  text-decoration: none !important;
  cursor: pointer;
}

input::placeholder {
  padding: 10px 0px;
  /* Adjust placeholder padding */

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-select-selector {
  min-height: 40px;
}